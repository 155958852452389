var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"w-100 justify-content-center"},[_c('CCol',{attrs:{"col":"12","sm":"8","md":"6","lg":"6","xl":"5"}},[_c('CCard',{staticClass:"table-setting-style"},[_c('CCardHeader',[_c('h3',[_vm._v("Налаштування сервісу")]),(_vm.errors.length)?_c('ul',{staticClass:"p-0",staticStyle:{"list-style-type":"none"}},_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_c('CAlert',{staticStyle:{"font-size":"12px"},attrs:{"show":"","color":"danger"}},[_vm._v(_vm._s(error))])],1)}),0):_vm._e(),(_vm.success)?_c('CAlert',{staticStyle:{"font-size":"12px"},attrs:{"show":"","color":"success"}},[_vm._v("Налаштування успішно збережені! ")]):_vm._e()],1),_c('CCardBody',[_c('CForm',[_c('div',{staticClass:"form-item mb-3",class:{'errorInput': _vm.$v.mail_data.mail_login.$error}},[_c('label',[_vm._v("Введіть E-mail")]),_c('CInput',{staticClass:"mb-0",class:{
                    'error': _vm.$v.mail_data.mail_login.$error
                  },attrs:{"required":"","placeholder":"Email","prepend":"@","autocomplete":"email"},on:{"change":function($event){return _vm.$v.mail_data.mail_login.$touch()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.mail_data.mail_login),callback:function ($$v) {_vm.$set(_vm.mail_data, "mail_login", $$v)},expression:"mail_data.mail_login"}}),(!_vm.$v.mail_data.mail_login.required)?_c('small',{staticClass:"form-text errorText w-100"},[_vm._v("Необхідно вказати Mail.")]):_vm._e(),(!_vm.$v.mail_data.mail_login.email)?_c('small',{staticClass:"form-text errorText w-100"},[_vm._v("Вказажіть коректний Mail.")]):_vm._e()],1),_c('div',{staticClass:"form-item mb-3",class:{'errorInput': _vm.$v.mail_data.mail_password.$error}},[_c('label',[_vm._v("Введіть Пароль")]),_c('CInput',{staticClass:"mb-0",class:{
                    'error': _vm.$v.mail_data.mail_password.$error
                  },attrs:{"required":"","placeholder":"Пароль","type":_vm.passwordFieldIdType,"autocomplete":"curent-password"},on:{"change":function($event){return _vm.$v.mail_data.mail_password.$touch()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-lock-locked"}})]},proxy:true},{key:"append-content",fn:function(){return [_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                  content: 'Показати/сховати пароль',
                                  placement: 'top'
                              }),expression:"{\n                                  content: 'Показати/сховати пароль',\n                                  placement: 'top'\n                              }"}],on:{"click":_vm.showPass}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1)]},proxy:true}]),model:{value:(_vm.mail_data.mail_password),callback:function ($$v) {_vm.$set(_vm.mail_data, "mail_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"mail_data.mail_password"}}),(!_vm.$v.mail_data.mail_password.required)?_c('small',{staticClass:"form-text errorText w-100"},[_vm._v("Необхідно вказати Пароль.")]):_vm._e()],1)])],1),_c('CCardFooter',[_c('CRow',{staticClass:"justify-content-between flex-sm-wrap"},[_c('CCol',{attrs:{"col":"","sm":"4","lg":"3"}},[_c('CButton',{staticClass:"btn-block flex-nowrap",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Назад ")])],1),_c('CCol',{attrs:{"col":"","sm":"5"}},[(_vm.loading)?_c('CButton',{staticClass:"btn-block flex-nowrap",attrs:{"color":"primary","disabled":""}},[_c('div',{staticClass:"align-items-center"},[_c('CSpinner',{attrs:{"color":"default","size":"sm"}})],1)]):_c('CButton',{staticClass:"btn-block",attrs:{"color":"primary"},on:{"click":_vm.validation}},[_vm._v(" Зберегти ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }