import axios from 'axios';

const APISetPass = (data) => {
    try {
        return axios.post('/api/settings/app', data)
    } catch (e) {
        return e.response.data
    }
}
const APIGetPass = () => {
    try {
        return axios.get('/api/settings/app').then(resp => resp.data)
    } catch (e) {
        return e;
    }
}

export const setPass = (users, callback_func) => {
    const apiGood = APISetPass(users);
    let response = {
        isOK: false,
        text: ''
    }

    apiGood.then(res => res.data).then(() => response.isOK = true).catch(error => {
        response.isOK = false;
        response.text = error.response.data;
    });
    callback_func(response);
}

export const getPass = (callback_func) => {
    const apiDataPass = APIGetPass();

    apiDataPass.then(result => {
        callback_func(result)
    })

}
